<template lang="html">
    <DsInlineAlert leading-icon="message-square" style="margin: 0" type="subtle">
        <h5 v-if="commentsByDate.length">
            {{ commentsByDate.length }} {{ commentsByDate.length === 1 ? 'Comment' : 'Comments' }}
        </h5>

        <h5 v-else>
            Project comments
        </h5>

        <div v-if="loaded" class="comments-list">
            <div
                v-for="({ dateAdded, authorId, message }, index) in commentsByDate"
                :key="`${authorId}-${dateAdded}`"
                class="comment-box"
            >
                <router-link
                    slot="leadingSlot"
                    :to="{ name: 'user', params: { id: authorId } }"
                >
                    <ds-avatar
                        :image-url="getAvatar(authorId)"
                        :size="40"
                    />
                </router-link>

                <div class="comment-content">
                    <router-link :to="{ name: 'user', params: { id: authorId } }">
                        {{ getAuthorName(authorId) }}
                    </router-link>

                    <small class="comment-timestamp">
                        {{ moment(dateAdded).fromNow() }}
                    </small>

                    <DsIconButton
                        v-if="isCommentAuthor(authorId)"
                        name="trash-2"
                        class="negative"
                        @click="promptDeleteComment(index)"
                    />

                    <br />

                    <div class="comment-bubble">
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>

        <project-comments-placeholder v-else />

        <template #footer>
            <div class="comment-box add">
                <ds-avatar :image-url="user.photoUrl" :size="40" />

                <div>
                    <ds-text-area-field
                        v-model.trim="newComment"
                        label="Add a comment"
                    />

                    <DsFilledButton
                        :loading="saving"
                        gray
                        @click="addComment"
                    >
                        Add a comment
                    </DsFilledButton>
                </div>
            </div>
        </template>
    </DsInlineAlert>
</template>

<script>
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { mapState, mapGetters } from 'vuex';
import ProjectCommentsPlaceholder from '@/components/project/ProjectCommentsPlaceholder';

export default {
    components: {
        ProjectCommentsPlaceholder,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            moment,
            newComment: '',
            loaded: false,
            saving: false,
        };
    },

    mounted() {
        this.loadComments();
    },

    watch: {
        projectId() {
            this.loadComments();
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            users: ({ users }) => users.users,
        }),

        ...mapGetters({
            commentsByDate: 'projects/commentsByDate',
        }),

        projectId() {
            return this.project.id;
        },
    },

    methods: {
        getAuthorName(authorId) {
            return this.users.find(({ id }) => {
                return id === authorId;
            }).name;
        },

        isCommentAuthor(authorId) {
            return authorId === this.user.uid;
        },

        getAvatar(authorId) {
            return this.users.find(({ id }) => {
                return id === authorId;
            }).photoUrl;
        },

        async loadComments() {
            this.loaded = false;

            this.$store.dispatch('projects/LOAD_PROJECT_COMMENTS', this.projectId)
                .catch((e) => {
                    this.$error({ message: `Error loading project messages: ${e}` });
                });

            this.loaded = true;
        },

        async addComment() {
            if (!this.newComment) {
                return;
            }

            this.saving = true;
            const comments = cloneDeep(this.commentsByDate);

            comments.push({
                authorId: this.user.uid,
                message: this.newComment,
                dateAdded: moment().format(),
            });

            await this.$store.dispatch('projects/SAVE_COMMENTS', { projectId: this.projectId, comments })
                .catch((e) => {
                    this.resetComment();
                    this.$error({ message: `Error adding comment: ${e}` });
                });

            this.resetComment();
            this.$toast({ message: 'Comment added' });
        },

        promptDeleteComment(index) {
            this.$confirm({
                title: 'Delete comment',
                message: 'Are you sure you want to delete this comment?',
                confirmLabel: 'Yes, delete',
                destructive: true,
            })
                .then(() => {
                    this.deleteComment(index);
                })
                .catch(() => {});
        },

        async deleteComment(index) {
            const comments = cloneDeep(this.commentsByDate);

            comments.splice(index, 1);

            await this.$store.dispatch('projects/SAVE_COMMENTS', { projectId: this.projectId, comments })
                .catch((e) => {
                    this.$error({ message: `Error deleting comment: ${e}` });
                });

            this.$toast({ message: 'Comment deleted' });
        },

        resetComment() {
            this.saving = false;
            this.newComment = '';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .comment-box {
        margin-top: $spacing-200;
        display: grid;
        grid-template-columns: px-to-rem(40px) auto;
        grid-gap: $spacing-200;
        --input-margin-bottom: #{$spacing-200};

        &.add {
            margin-bottom: $spacing-400;
        }
    }

    .comment-timestamp {
        color: $color-gray-light;
        margin: 0 $spacing-100;
    }

    .comment-bubble {
        display: inline-flex;
        margin-top: $spacing-100;
        background-color: $color-gray-050;
        padding: $spacing-200;
        border-radius: $border-radius;
        border-top-left-radius: 0;
        white-space: pre-wrap;
    }

    a {
        color: $color-text;
    }

    .empty {
        img {
            height: px-to-rem(200px);
        }
    }
</style>
