<template>
    <simple-page-layout padded>
        <div v-if="loading" class="loading-spinner">
            <ds-spinner />
        </div>

        <project-details v-else :project="project" />
    </simple-page-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SimplePageLayout from '@/components/SimplePageLayout';
import ProjectDetails from '@/components/ProjectDetails';

export default {
    components: {
        SimplePageLayout,
        ProjectDetails,
    },

    data() {
        return {
            loading: true,
        };
    },

    mounted() {
        this.loadProject();
    },

    watch: {
        projectId() {
            this.loadProject();
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            users: ({ users }) => users.users,
            project: ({ projects }) => projects.project,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        ...mapGetters({
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
            winningProjectIds: 'hackathons/winningProjectIds',
        }),

        projects() {
            return this.getProjectsByHackathon(this.project?.hackathonId);
        },

        projectId() {
            return this.$route.params.id;
        },
    },

    methods: {
        async loadProject() {
            try {
                this.loading = true;

                await this.$store.dispatch('projects/LOAD_PROJECT', this.projectId);
            } catch (e) {
                this.$error({ message: `Error loading project: ${e}` });
            }

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>

</style>
