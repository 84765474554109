<template>
    <simple-page-layout :padded="!demoInProgress">
        <portal to="title">
            Demo
        </portal>

        <div :class="{ 'demo-active': isAdmin && demoInProgress }">
            <aside v-if="isAdmin && demoInProgress && demoProjects.length">
                <projects-list
                    compact
                    :projects="demoProjects"
                    @selected="selectProject"
                />
            </aside>

            <DsInlineAlert v-if="!demoInProgress">
                No demo in progress

                <template #footer>
                    <DsFilledButton
                        v-if="isAdmin"
                        gray
                        @click="startDemo"
                    >
                        <DsStatus emphasis="high">
                            Admin
                        </DsStatus>
                        Start demo
                    </DsFilledButton>
                </template>
            </DsInlineAlert>

            <div v-if="demoProject.id" class="demo-stage">
                <ProjectDetails :project="demoProject" padded />
            </div>
        </div>
    </simple-page-layout>
</template>

<script>
// import moment from 'moment';
import ProjectsList from '@/components/ProjectsList';
import ProjectDetails from '@/components/ProjectDetails';
import SimplePageLayout from '@/components/SimplePageLayout';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        SimplePageLayout,
        ProjectDetails,
        ProjectsList,
    },

    data() {
        return {
            loading: true,
            autoUpdate: true,
            projectOverride: null,
        };
    },

    created() {
        this.load();
    },

    computed: {
        ...mapState({
            demo: ({ hackathons }) => hackathons.demo,
            hackathons: ({ hackathons }) => hackathons.hackathons,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        ...mapGetters({
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
            getProject: 'projects/getProject',
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        demoProjects() {
            const hackathonProjects = this.getProjectsByHackathon(this.demo?.hackathonId);

            return hackathonProjects?.filter(({ disablePresenting }) => !disablePresenting)?.sort((a, b) => {
                if (a.createdDate > b.createdDate) return 1;
                if (a.createdDate < b.createdDate) return -1;

                return 0;
            });
        },

        demoProject() {
            return this.getProject(this.demo.projectId);
        },

        demoInProgress() {
            return Boolean(this.demo.hackathonId);
        },
    },

    methods: {
        async load() {
            try {
                this.loading = true;
                await this.$store.dispatch('hackathons/LOAD_HACKATHON_DEMO');
            } catch (e) {
                //
            }

            this.loading = false;
        },

        async startDemo() {
            const projectId = this.getProjectsByHackathon(this.activeHackathon.id)?.[0]?.id;

            const payload = {
                hackathonId: this.activeHackathon.id,
                projectId,
                // startTime: moment().format(),
            };

            await this.$store.dispatch('hackathons/SET_HACKATHON_DEMO', payload);
        },

        async selectProject(project) {
            if (!this.isAdmin) return;

            const payload = {
                hackathonId: project.hackathonId,
                projectId: project.id,
                // startTime: moment().format(),
            };

            await this.$store.dispatch('hackathons/SET_HACKATHON_DEMO', payload);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
aside {
    height: calc(100vh - 120px);
    overflow: auto;
    @include custom-scroll-bar;

    @media($extra-small) {
        display: none;
    }
}

.demo-stage {
    display: flex;
    height: calc(100vh - 120px);
    overflow: auto;
    @include custom-scroll-bar;

    @media($extra-small) {
        padding: 0;
    }
}

.demo-active {
    display: grid;
    grid-template-columns: 320px auto;
    grid-gap: $spacing-200;

    @media($extra-small) {
        grid-template-columns: auto;
    }
}
</style>
