<template>
    <simple-page-layout padded>
        <portal to="title">
            Vote
        </portal>

        <div v-if="loading" class="loading-spinner">
            <ds-spinner />
        </div>

        <div v-else :class="`awards grid-${awardCount}`">
            <AwardVoteCard
                v-for="(award, awardIndex) in hackathon.awards"
                :key="awardIndex"
                :award="award"
                :award-index="awardIndex"
            />
        </div>
    </simple-page-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SimplePageLayout from '@/components/SimplePageLayout';
import AwardVoteCard from '@/components/AwardVoteCard';

export default {
    components: {
        SimplePageLayout,
        AwardVoteCard,
    },

    data() {
        return {
            loading: true,
        };
    },

    mounted() {
        this.loadVotes();
    },

    computed: {
        awardCount() {
            return this.hackathon?.awards?.length;
        },

        hackathonId() {
            return this.$route.params?.id;
        },

        hackathon() {
            return this.hackathonId ? this.getHackathon(this.hackathonId) : this.getActiveHackathon;
        },

        filteredProjects() {
            return this.getProjectsByHackathon(this.hackathon.id).filter((project) => !project.disablePresenting);
        },

        currentAward() {
            return this.hackathon?.awards?.[this.awardIndex] || {};
        },

        hasAwards() {
            return this.hackathon?.awards?.length > 0;
        },

        ...mapState({
            hackathons: ({ hackathons }) => hackathons.hackathons,
            projects: ({ projects }) => projects.projects,
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            getTag: 'tags/getTag',
            getActiveHackathon: 'hackathons/getActiveHackathon',
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
            getHackathon: 'hackathons/getHackathon',
            getProject: 'projects/getProject',
            getUserVote: 'votes/getUserVote',
        }),
    },

    methods: {
        async loadVotes() {
            this.loading = true;

            await this.$store.dispatch('votes/LOAD_VOTES');

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .awards {
        display: grid;
        grid-gap: $spacing-200;

        &.grid-2 { grid-template-columns: repeat(2, calc(50% - $spacing-100)) }
        &.grid-3 { grid-template-columns: repeat(3, calc(calc(100% - $spacing-200 * 2) / 3)) }
    }
</style>
