<template lang="html">
    <aside class="projects-sidebar">
        <div class="hackathon-banner">
            <img
                v-if="hackathon.image"
                :src="hackathon.image"
                :alt="hackathon.name"
                class="hackathon-image"
            />

            <h2>
                {{ hackathon.name }}
            </h2>
        </div>

        <div class="projects">
            <h5 class="title">
                {{ sortedProjects.length }} Projects
            </h5>

            <projects-list
                v-if="sortedProjects.length"
                :projects="sortedProjects"
                compact
                @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
            />
        </div>
    </aside>
</template>

<script>
import { mapState } from 'vuex';
import ProjectsList from '@/components/ProjectsList';

export default {
    components: {
        ProjectsList,
    },

    props: {
        projects: Array,
    },

    computed: {
        ...mapState({
            comments: ({ projects }) => projects.comments,
            hackathon: ({ hackathons }) => hackathons.hackathon,
            project: ({ projects }) => projects.project,
        }),

        sortedProjects() {
            // eslint-disable-next-line
            return this.projects?.sort((a, b) => {
                if (a.createdDate > b.createdDate) return 1;
                if (a.createdDate < b.createdDate) return -1;

                return 0;
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.projects-sidebar {
    border-radius: $border-radius;
    overflow: hidden;
    margin-right: $spacing-200;
    max-height: calc(100vh - 104px);

    @media($small) {
        margin: 0;
    }
}

.hackathon-banner {
    display: none;
    gap: $spacing-200;
    padding: $spacing-200;
    grid-template-columns: 80px auto;
    align-items: center;

    @media($small) {
        display: grid;
    }
}

.hackathon-image {
    border-radius: $border-radius;
    max-height: 200px;
    width: auto;
}

.projects {
    height: calc(100vh - 104px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    @include custom-scroll-bar;
}

aside {
    background: $color-gray-050;
    border-radius: $border-radius;
    @include custom-scroll-bar;
}

.back-button {
    margin: $spacing-100;
    width: calc(100% - $spacing-200);
}

header {
    text-align: center;
    padding: $spacing-200;
}

.title {
    padding: $spacing-200;
}
</style>
